import React, { useRef } from 'react';
import { Grid, Typography } from '@mui/material';

import { WindowTypes } from '../../contexts';
import { enumToString } from '../../helpers';
import useStyles from './styles';

interface IWindowInput {
  windowType: WindowTypes;
  onChange: (windowType: WindowTypes, quantity: number) => void;
  value: number | undefined;
}

export const WindowInput = ({ value, windowType, onChange }: IWindowInput) => {
  const classes = useStyles();

  const windowTypePathNumber = (windowType: WindowTypes) => {
    switch (windowType) {
      case WindowTypes.WITHOUT_MULLION:
        return 1;
      case WindowTypes.OVERHUNG:
        return 2;
      case WindowTypes.WITH_MULLION:
        return 3;
    }
  };
  const input = useRef<HTMLInputElement>(null);

  return (
    <Grid container direction="row" className={classes.container}>
      <img
        src={`/assets/window-${windowTypePathNumber(windowType)}.png`}
        alt="window"
        className={classes.image}
      />
      <Grid container item direction="column" xs>
        <Grid item>
          <Typography variant="body1" className={classes.label}>
            {enumToString(windowType)}
          </Typography>
        </Grid>
        <Grid item>
          <input
            value={value}
            placeholder="0"
            type="number"
            className={classes.input}
            onWheel={() => {
              input.current?.blur();
            }}
            ref={input}
            onChange={(e) => onChange(windowType, e.target.valueAsNumber)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
