import { gql } from '@apollo/client';

export const CREATE_CLIENT_AND_ISSUE_WINDOW_CLEANING = gql`
  mutation createClientAndIssueWindowCleaning(
    $userInput: UserInputWindowCleaning!
    $responsibleEmployeeId: String!
  ) {
    createClientAndIssueWindowCleaning(
      userInput: $userInput
      responsibleEmployeeId: $responsibleEmployeeId
    )
  }
`;
