import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

import useStyles from './styles';

interface IFormSection {
  title: string;
  tagline?: string;
  children: React.ReactNode;
}

export const FormSection = ({ children, title, tagline }: IFormSection) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Box className={classes.headerContainer}>
        <Typography variant="h3" className={classes.tagline}>
          {tagline}
        </Typography>
        <Typography variant="h2" className={classes.header}>
          {title}
        </Typography>
      </Box>
      {children}
    </Paper>
  );
};
