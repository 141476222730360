import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    box: {
      border: `1px solid ${theme.palette.grey[500]}`,
      padding: 18,
      height: '100%',
      width: '100%',
      borderRadius: theme.shape.borderRadius,
      transition: 'background-color 0.25s ease, border-color 0.25s ease',
      cursor: 'pointer',
    },
    textChecked: {
      display: 'flex',
      // Checkmark icon
      '&::before': {
        content: '"t"',
        fontFamily: 'alberts',
        fontSize: 20,
        marginRight: 15,
        color: theme.palette.primary.main,
      },
    },
    textNotChecked: {
      display: 'flex',
      position: 'relative',
      // Checkmark icon
      '&::before': {
        content: '"t"',
        fontFamily: 'alberts',
        fontSize: 20,
        color: theme.palette.grey[500],
        marginRight: 15,
      },
      // Circle icon, overlays the checkmark to give the appearance of it being unchecked
      '&::after': {
        content: '"g"',
        fontFamily: 'alberts',
        position: 'absolute',
        top: 5,
        left: 3,
        fontSize: 14,
        color: theme.palette.common.white,
        transition: 'color 0.25s ease',
        zIndex: 999,
      },
    },
  })
);
