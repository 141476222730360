import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.grey[500]}`,
      minHeight: 158,
      padding: 28,
    },
    image: {
      marginRight: 30,
      width: 80,
      height: 'auto',
    },
    label: {
      marginBottom: 13,
      color: theme.palette.grey[200],
    },
    input: {
      borderRadius: 32,
      border: 'none',
      outline: 'none',
      backgroundColor: theme.palette.grey[600],
      padding: 8,
      fontWeight: 700,
      textAlign: 'center',
      fontSize: 18,
      width: 100,
      height: 42,
      // Removes increment arrows
      '-moz-appearance': 'textfield !important',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none !important',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none !important',
        margin: 0,
      },
    },
  })
);
