import {
  CleaningServiceTypes,
  DefaultCleaningServiceTypes,
  IContactInformation,
  IDefaultCleaningForm,
  IFormSubmissionState,
  IHomeCleaningForm,
  IMeetingInformation,
  IWindowCleaningForm,
  MeetingTypes,
  ResidenceAccessTypes,
  WindowPropertyDescriptions,
  WindowServiceTypes,
  ITimewaveInformationState,
} from './types';

export interface IFormState {
  fieldErrors: Record<
    keyof IContactInformation | keyof IMeetingInformation,
    boolean
  >;
  serviceType: DefaultCleaningServiceTypes | CleaningServiceTypes;
  homeCleaningFormValues: IHomeCleaningForm;
  windowCleaningFormValues: IWindowCleaningForm;
  defaultCleaningFormValues: IDefaultCleaningForm;
  formSubmissionState: IFormSubmissionState;
  timewaveInformationState: ITimewaveInformationState;
}

export const initialFormState: IFormState = {
  serviceType: CleaningServiceTypes.HOME,
  homeCleaningFormValues: {
    meetingType: MeetingTypes.VIDEO,
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    postalCode: '',
    phone: '',
    email: '',
    sqmSize: '',
    meetingEmployeeId: '',
    startDate: '',
    startTime: '',
    endTime: '',
  },
  windowCleaningFormValues: {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    postalCode: '',
    portCode: '',
    hasPortCode: false,
    phone: '',
    email: '',
    serviceType: WindowServiceTypes.WINDOWS,
    nrOfMullionWindows: 0,
    nrOfNonMullionWindows: 0,
    nrOfOverhungWindows: 0,
    windowPropertyDescription: WindowPropertyDescriptions.DOUBLE,
    isLadderNeeded: false,
    isFrameCleaningNeeded: false,
    residenceAccessType: ResidenceAccessTypes.IN_PERSON,
  },
  defaultCleaningFormValues: {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    postalCode: '',
    phone: '',
    email: '',
    sqmSize: '',
  },
  fieldErrors: {
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    postalCode: false,
    phone: false,
    email: false,
    sqmSize: false,
    startDate: false,
    startTime: false,
    endTime: false,
    meetingEmployeeId: false,
  },
  formSubmissionState: {
    data: false,
    error: false,
    loading: false,
  },
  timewaveInformationState: {
    employeeIdsConfig: {
      responsibleEmployeeId: '',
      physicalMeetingEmployeeIds: { gothenburg: '', stockholm: '' },
      digitalMeetingEmployeeIds: { gothenburg: '', stockholm: '', uppsala: '' },
    },
    serviceIdsConfig: {
      physicalMeetingServiceIds: { gothenburg: '', stockholm: '' },
      digitalMeetingServiceIds: { gothenburg: '', stockholm: '', uppsala: '' },
    },
  },
};
