import React from 'react';
import { Box, Typography } from '@mui/material';

import useStyles from './styles';

interface IMultipleCheckbox {
  checkedIndex: number;
  choices: string[];
  onCheck: (index?: number) => void;
}

export const MultipleChoiceCheckbox = ({
  checkedIndex,
  choices,
  onCheck,
}: IMultipleCheckbox) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      {choices.map((choice, index) => (
        <Typography
          key={index}
          // If choice is checked, undefined is returned to uncheck it. Otherwise choice index is returned for it to be checked
          onClick={() => onCheck(checkedIndex === index ? undefined : index)}
          variant="body1"
          className={
            checkedIndex === index
              ? classes.textChecked
              : classes.textNotChecked
          }
        >
          {choice}
        </Typography>
      ))}
    </Box>
  );
};
