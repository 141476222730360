import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      paddingTop: '9px',
    },
    checkbox: {
      color: theme.palette.grey[500],
      padding: '0 9px 0 0',
    },
  })
);
