import React from 'react';
import { Grid } from '@mui/material';

import { FormSection } from '../../FormSection';
import {
  CleaningServiceTypes,
  DefaultCleaningServiceTypes,
  IDefaultCleaningForm,
  IHomeCleaningForm,
} from '../../../contexts';
import { Input } from '../../Input';
import { Checkbox } from '../../Checkbox';
import stringData from '../../../assets/data/formStringsData.json';
import { useFormContext } from '../../../hooks';
import { enumToString } from '../../../helpers';

export const ResidenceInformation = () => {
  const {
    setFormValues,
    windowCleaningFormValues,
    serviceType,
    fieldErrors,
    homeCleaningFormValues,
    defaultCleaningFormValues,
  } = useFormContext();
  const residenceInformationStrings = stringData.steps.residenceInformation;

  const getCurrentFormValues = () => {
    switch (serviceType) {
      case CleaningServiceTypes.HOME:
        return homeCleaningFormValues;
      case CleaningServiceTypes.WINDOW:
        return windowCleaningFormValues;
      case DefaultCleaningServiceTypes.MAJOR:
      case DefaultCleaningServiceTypes.MOVING:
        return defaultCleaningFormValues;
    }
  };

  return (
    <>
      {serviceType === CleaningServiceTypes.WINDOW ? (
        <>
          <FormSection
            title={residenceInformationStrings.window.isLadderNeeded.title}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <Checkbox
                  text={
                    residenceInformationStrings.window.isLadderNeeded.choices
                      .true
                  }
                  isChecked={windowCleaningFormValues.isLadderNeeded === true}
                  onClick={() => setFormValues('isLadderNeeded', true)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Checkbox
                  text={
                    residenceInformationStrings.window.isLadderNeeded.choices
                      .false
                  }
                  isChecked={windowCleaningFormValues.isLadderNeeded === false}
                  onClick={() => setFormValues('isLadderNeeded', false)}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection
            title={residenceInformationStrings.window.frameCleaning.title}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <Checkbox
                  text={
                    residenceInformationStrings.window.frameCleaning.choices
                      .true
                  }
                  isChecked={
                    windowCleaningFormValues.isFrameCleaningNeeded === true
                  }
                  onClick={() => setFormValues('isFrameCleaningNeeded', true)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Checkbox
                  text={
                    residenceInformationStrings.window.frameCleaning.choices
                      .false
                  }
                  isChecked={
                    windowCleaningFormValues.isFrameCleaningNeeded === false
                  }
                  onClick={() => setFormValues('isFrameCleaningNeeded', false)}
                />
              </Grid>
            </Grid>
          </FormSection>
        </>
      ) : (
        <FormSection
          title={residenceInformationStrings.default.residenceInformation.title}
          tagline={enumToString(serviceType)}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                placeholder={
                  residenceInformationStrings.default.residenceInformation
                    .fields.sqmSize.placeholder
                }
                error={fieldErrors.sqmSize}
                errorHelperText={
                  residenceInformationStrings.default.residenceInformation
                    .fields.sqmSize.errorHelperText
                }
                key={'sqmSize'}
                value={
                  (
                    getCurrentFormValues() as
                      | IHomeCleaningForm
                      | IDefaultCleaningForm
                  )['sqmSize']
                }
                onChange={(value) => {
                  setFormValues('sqmSize', value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                placeholder={
                  residenceInformationStrings.default.residenceInformation
                    .fields.postalCode.placeholder
                }
                error={fieldErrors.postalCode}
                errorHelperText={
                  residenceInformationStrings.default.residenceInformation
                    .fields.postalCode.errorHelperText
                }
                type="number"
                value={getCurrentFormValues()['postalCode']}
                onChange={(value) => setFormValues('postalCode', value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                placeholder={
                  residenceInformationStrings.default.residenceInformation
                    .fields.address.placeholder
                }
                error={fieldErrors.address}
                errorHelperText={
                  residenceInformationStrings.default.residenceInformation
                    .fields.address.errorHelperText
                }
                value={getCurrentFormValues()['address']}
                onChange={(value) => setFormValues('address', value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                placeholder={
                  residenceInformationStrings.default.residenceInformation
                    .fields.city.placeholder
                }
                error={fieldErrors.city}
                errorHelperText={
                  residenceInformationStrings.default.residenceInformation
                    .fields.city.errorHelperText
                }
                value={getCurrentFormValues()['city']}
                onChange={(value) => setFormValues('city', value)}
              />
            </Grid>
          </Grid>
        </FormSection>
      )}
    </>
  );
};
