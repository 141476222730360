import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import stringData from '../../assets/data/formStringsData.json';
import { GET_AVAILABLE_TIME_GAPS } from '../../gql';
import {
  calculateMonthlyApiTimeIntervalUnix,
  formatDisplayDate,
  formatUserInputDate,
} from './helpers';
import { ICalendar, ITimeGap } from './types';
import { CalendarItem } from './components';
import { useFormContext } from '../../hooks';
import { IMeetingInformation } from '../../contexts';
import useStyles from './styles';
import { VALIDATION_ERROR_UPPSALA } from '../../helpers';

export const Calendar = ({
  gapLengthHours,
  meetingType,
  noMeetingSelectedError,
  handleScrollToButton,
}: ICalendar) => {
  const classes = useStyles();

  const { homeCleaningFormValues, setFormValues, timewaveInformationState } =
    useFormContext();

  const [timeGaps, setTimeGaps] = useState<Record<string, ITimeGap[]>>({});
  const [localPagination, setLocalPagination] = useState<number>(0);
  const [apiPagination, setApiPagination] = useState<number>(0);
  const calendarStrings = stringData.steps.chooseService.home.meetingDateTime;

  const [getTimeGaps, { loading, error, refetch }] = useLazyQuery(
    GET_AVAILABLE_TIME_GAPS,
    {
      onCompleted: (data) => {
        const timeGaps = data.getAvailableTimeGaps;
        const groupedData = _.groupBy(timeGaps, 'startDate');
        setTimeGaps((prevState) => ({
          ...prevState,
          ...groupedData,
        }));
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    const { endDateUnix, startDateUnix } =
      calculateMonthlyApiTimeIntervalUnix(apiPagination);
    getTimeGaps({
      variables: {
        gapLengthHours,
        startDateUnix,
        endDateUnix,
        meetingType,
        postalCode: homeCleaningFormValues.postalCode,
        employeeIdsConfig: timewaveInformationState.employeeIdsConfig,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFormDataMeetingInformation = ({
    startDate,
    startTime,
    endTime,
    meetingEmployeeId,
  }: Partial<IMeetingInformation>) => {
    setFormValues('startDate', startDate);
    setFormValues('startTime', startTime);
    setFormValues('endTime', endTime);
    setFormValues('meetingEmployeeId', meetingEmployeeId);
  };

  const resetFormDataMeetingInformation = () => {
    setFormValues('startDate', '');
    setFormValues('startTime', '');
    setFormValues('endTime', '');
    setFormValues('meetingEmployeeId', '');
  };

  const handleClick = (timeGap: ITimeGap) => {
    handleScrollToButton();
    setFormDataMeetingInformation({
      startDate: formatUserInputDate(timeGap.startDate),
      startTime: timeGap.startTime,
      endTime: timeGap.endTime,
      meetingEmployeeId: timeGap.employeeId,
    });
  };

  const increasePagination = () => {
    // local timeGaps state has been exhausted
    if (localPagination + 1 >= calculateWeeksInTimeGapsState()) {
      // api call to get next four weeks
      const { endDateUnix, startDateUnix } =
        calculateMonthlyApiTimeIntervalUnix(apiPagination + 1);
      refetch({
        startDateUnix,
        endDateUnix,
      });
      setApiPagination(apiPagination + 1);
    }
    setLocalPagination(localPagination + 1);
    resetFormDataMeetingInformation();
  };

  const decreasePagination = () => {
    if (localPagination > 0) {
      setLocalPagination(localPagination - 1);
    }
    resetFormDataMeetingInformation();
  };

  const calculateWeeksInTimeGapsState = () => {
    const days = Object.keys(timeGaps).length;
    const weeks = days / 7;
    return weeks;
  };

  const getWeeklyTimeGapKeysFromState = () => {
    const startNumber = localPagination * 7;
    const endNumber = localPagination * 7 + 7;
    return Object.keys(timeGaps).slice(startNumber, endNumber);
  };

  if (loading) {
    return (
      <Box className={`${classes.centerContent} ${classes.loaderContainer}`}>
        <CircularProgress size={50} color="primary" />
      </Box>
    );
  }

  if (error) {
    //VALIDATION_ERROR_UPPSALA is used as a validation-lookup in backend.
    return (
      <>
        {error?.message === VALIDATION_ERROR_UPPSALA ? (
          <Typography className={classes.centerContent}>
            {calendarStrings.apiErrorTextUppsala}
          </Typography>
        ) : (
          <>
            <Typography className={classes.centerContent}>
              {calendarStrings.apiErrorText}
            </Typography>
            <Box className={classes.linkContainer}>
              <Typography className={classes.link}>
                {calendarStrings.bookThroughOldForm}
              </Typography>
              <Link
                target="_blank"
                href={calendarStrings.oldFormUrl}
                className={classes.link}
              >
                {calendarStrings.here}
              </Link>
            </Box>
          </>
        )}
      </>
    );
  }

  return (
    <Box className={classes.calendarContainer}>
      {getWeeklyTimeGapKeysFromState().map((date, index) => (
        <Box className={classes.dateContainer} key={index}>
          <Typography className={classes.dateText}>
            {formatDisplayDate(date)}
          </Typography>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={1}
            className={classes.itemsContainer}
          >
            {timeGaps[date].map((timeGap, index) => (
              <CalendarItem
                key={index}
                chosenStartDate={homeCleaningFormValues.startDate}
                chosenStartTime={homeCleaningFormValues.startTime}
                timeGap={timeGap}
                handleClick={handleClick}
              />
            ))}
          </Grid>
        </Box>
      ))}
      {noMeetingSelectedError && (
        <Typography className={classes.errorText}>
          {calendarStrings.noSelectedDateErrorText}
        </Typography>
      )}
      <Box className={classes.buttonContainer}>
        <button className={classes.primaryButton} onClick={increasePagination}>
          <Typography>{calendarStrings.forwardPaginationText}</Typography>
        </button>
        {localPagination > 0 && (
          <button
            className={classes.secondaryButton}
            onClick={decreasePagination}
          >
            <Typography>{calendarStrings.backwardsPaginationText}</Typography>
          </button>
        )}
      </Box>
    </Box>
  );
};
