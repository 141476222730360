import React, { ReactNode, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { FormContext } from './context';
import {
  CleaningServiceTypes,
  DefaultCleaningServiceTypes,
  IContactInformation,
  IDefaultCleaningForm,
  IFormSubmissionState,
  IHomeCleaningForm,
  IMeetingInformation,
  IWindowCleaningForm,
  MeetingTypes,
  ResidenceAccessTypes,
  ValueOf,
  WindowPropertyDescriptions,
  WindowServiceTypes,
  ITimewaveInformationState,
} from './types';
import {
  CREATE_CLIENT_AND_ISSUE_DEFAULT,
  CREATE_CLIENT_AND_ISSUE_WINDOW_CLEANING,
  CREATE_QUOTE_HOME_CLEANING,
} from '../../gql';
import { EmailType, SendEmail } from '../../helpers/sendEmail';

interface IFormProvider {
  children: ReactNode;
}

export const FormProvider = ({ children }: IFormProvider) => {
  const [
    createClientAndIssueDefault,
    {
      data: defaultFormSubmissionData,
      error: defaultFormSubmissionError,
      loading: defaultFormSubmissionLoading,
    },
  ] = useMutation(CREATE_CLIENT_AND_ISSUE_DEFAULT);
  const [
    createClientAndIssueWindowCleaning,
    {
      data: windowFormSubmissionData,
      error: windowFormSubmissionError,
      loading: windowFormSubmissionLoading,
    },
  ] = useMutation(CREATE_CLIENT_AND_ISSUE_WINDOW_CLEANING);
  const [
    createQuoteHomeCleaning,
    {
      data: homeFormSubmissionData,
      error: homeFormSubmissionError,
      loading: homeFormSubmissionLoading,
    },
  ] = useMutation(CREATE_QUOTE_HOME_CLEANING);
  const [formSubmissionState, setFormSubmissionState] =
    useState<IFormSubmissionState>({
      loading: false,
      data: false,
      error: false,
    });
  useEffect(() => {
    if (
      defaultFormSubmissionLoading ||
      windowFormSubmissionLoading ||
      homeFormSubmissionLoading
    ) {
      setFormSubmissionState((prevState) => ({
        ...prevState,
        loading: true,
      }));
    }

    if (
      defaultFormSubmissionError ||
      windowFormSubmissionError ||
      homeFormSubmissionError
    ) {
      setFormSubmissionState({
        error: true,
        loading: false,
        data: false,
      });
    }

    if (
      defaultFormSubmissionData ||
      windowFormSubmissionData ||
      homeFormSubmissionData
    ) {
      setFormSubmissionState({
        data: true,
        loading: false,
        error: false,
      });
    }
  }, [
    defaultFormSubmissionData,
    defaultFormSubmissionError,
    defaultFormSubmissionLoading,
    windowFormSubmissionData,
    windowFormSubmissionError,
    windowFormSubmissionLoading,
    homeFormSubmissionData,
    homeFormSubmissionError,
    homeFormSubmissionLoading,
  ]);

  const [serviceType, setServiceType] = useState<
    DefaultCleaningServiceTypes | CleaningServiceTypes
  >(CleaningServiceTypes.HOME);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      //event is set in header.php in Alberts theme. If changes are made don't forget to update here as well.
      if (event.data.source === 'albertsstad') {
        if (event.data.data.employeeIdsConfig) {
          setTimewaveInformationStates((prevValue) => ({
            ...prevValue,
            employeeIdsConfig: event.data.data.employeeIdsConfig,
          }));
        }
        if (event.data.data.serviceIdsConfig) {
          setTimewaveInformationStates((prevValue) => ({
            ...prevValue,
            serviceIdsConfig: event.data.data.serviceIdsConfig,
          }));
        }
        if (event.data.data.postalCode || event.data.data.sqmSize) {
          setHomeCleaningFormValues((prevValue) => ({
            ...prevValue,
            postalCode: event.data.data.postalCode,
            sqmSize: event.data.data.sqmSize,
            city: event.data.data.generatedCity,
          }));
          setWindowCleaningFormValues((prevValue) => ({
            ...prevValue,
            postalCode: event.data.data.postalCode,
            city: event.data.data.generatedCity,
          }));
          setDefaultCleaningFormValues((prevValue) => ({
            ...prevValue,
            postalCode: event.data.data.postalCode,
            sqmSize: event.data.data.sqmSize,
            city: event.data.data.generatedCity,
          }));
        }
      }
    });
  }, []);
  const [timewaveInformationState, setTimewaveInformationStates] =
    useState<ITimewaveInformationState>({
      employeeIdsConfig: {
        responsibleEmployeeId: '',
        physicalMeetingEmployeeIds: {
          gothenburg: '',
          stockholm: '',
        },
        digitalMeetingEmployeeIds: {
          gothenburg: '',
          stockholm: '',
          uppsala: '',
        },
      },
      serviceIdsConfig: {
        physicalMeetingServiceIds: {
          gothenburg: '',
          stockholm: '',
        },
        digitalMeetingServiceIds: {
          gothenburg: '',
          stockholm: '',
          uppsala: '',
        },
      },
    });
  const [fieldErrors, setFieldErrors] = useState<
    Record<keyof IContactInformation | keyof IMeetingInformation, boolean>
  >({
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    postalCode: false,
    phone: false,
    email: false,
    sqmSize: false,
    startDate: false,
    startTime: false,
    endTime: false,
    meetingEmployeeId: false,
  });

  const [homeCleaningFormValues, setHomeCleaningFormValues] =
    useState<IHomeCleaningForm>({
      meetingType: MeetingTypes.VIDEO,
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      postalCode: '',
      phone: '',
      email: '',
      sqmSize: '',
      meetingEmployeeId: '',
      startDate: '',
      startTime: '',
      endTime: '',
    });
  const [windowCleaningFormValues, setWindowCleaningFormValues] =
    useState<IWindowCleaningForm>({
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      postalCode: '',
      portCode: '',
      hasPortCode: false,
      phone: '',
      email: '',
      serviceType: WindowServiceTypes.WINDOWS,
      nrOfMullionWindows: 0,
      nrOfNonMullionWindows: 0,
      nrOfOverhungWindows: 0,
      windowPropertyDescription: WindowPropertyDescriptions.DOUBLE,
      isLadderNeeded: false,
      isFrameCleaningNeeded: false,
      residenceAccessType: ResidenceAccessTypes.IN_PERSON,
    });
  const [defaultCleaningFormValues, setDefaultCleaningFormValues] =
    useState<IDefaultCleaningForm>({
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      postalCode: '',
      phone: '',
      email: '',
      sqmSize: '',
    });

  const setFormValues = (
    key:
      | Partial<keyof IWindowCleaningForm>
      | Partial<keyof IHomeCleaningForm>
      | Partial<keyof IDefaultCleaningForm>,
    value:
      | Partial<ValueOf<IWindowCleaningForm>>
      | Partial<ValueOf<IHomeCleaningForm>>
      | Partial<ValueOf<IDefaultCleaningForm>>
  ) => {
    switch (serviceType) {
      case CleaningServiceTypes.HOME: {
        setHomeCleaningFormValues((prevValue) => ({
          ...prevValue,
          [key]: value,
        }));
        break;
      }
      case CleaningServiceTypes.WINDOW: {
        setWindowCleaningFormValues((prevValue) => ({
          ...prevValue,
          [key]: value,
        }));
        break;
      }
      case DefaultCleaningServiceTypes.MAJOR:
      case DefaultCleaningServiceTypes.MOVING: {
        setDefaultCleaningFormValues((prevValue) => ({
          ...prevValue,
          [key]: value,
        }));
      }
    }
  };

  const formValidationKeys: Record<
    number,
    Record<
      CleaningServiceTypes | DefaultCleaningServiceTypes,
      Array<keyof IContactInformation | keyof IMeetingInformation>
    >
  > = {
    0: {
      [CleaningServiceTypes.HOME]: [
        'meetingEmployeeId',
        'startDate',
        'startTime',
        'endTime',
        'postalCode',
      ],
      [CleaningServiceTypes.WINDOW]: [],
      [DefaultCleaningServiceTypes.MAJOR]: [],
      [DefaultCleaningServiceTypes.MOVING]: [],
    },
    1: {
      [CleaningServiceTypes.HOME]: ['sqmSize', 'postalCode', 'address', 'city'],
      [CleaningServiceTypes.WINDOW]: [],
      [DefaultCleaningServiceTypes.MAJOR]: [
        'sqmSize',
        'postalCode',
        'address',
        'city',
      ],
      [DefaultCleaningServiceTypes.MOVING]: [
        'sqmSize',
        'postalCode',
        'address',
        'city',
      ],
    },
    2: {
      [CleaningServiceTypes.HOME]: ['firstName', 'lastName', 'phone', 'email'],
      [CleaningServiceTypes.WINDOW]: [
        'firstName',
        'lastName',
        'phone',
        'email',
        'address',
        'postalCode',
        'city',
      ],
      [DefaultCleaningServiceTypes.MAJOR]: [
        'firstName',
        'lastName',
        'phone',
        'email',
      ],
      [DefaultCleaningServiceTypes.MOVING]: [
        'firstName',
        'lastName',
        'phone',
        'email',
      ],
    },
  };

  const testRules: Partial<
    Record<keyof IContactInformation | keyof IMeetingInformation, RegExp>
  > = {
    email: /^\S+@\S+\.\S+$/, // valid email syntax
    postalCode: /^\d{5}$/, // length of 5 numbers
  };

  const testValueAgainstRule = (
    key: keyof IContactInformation | keyof IMeetingInformation,
    value:
      | ValueOf<IHomeCleaningForm>
      | ValueOf<IWindowCleaningForm>
      | ValueOf<DefaultCleaningServiceTypes>
  ) => {
    const rule = testRules[key];
    if (rule && typeof value === 'string') {
      return rule.test(value);
    }
    return true;
  };

  const handleSetFieldError = (
    key: keyof IContactInformation | keyof IMeetingInformation,
    error: boolean
  ) => {
    setFieldErrors((prevState) => ({
      ...prevState,
      [key]: error,
    }));
  };

  const validateFields = (step: number) => {
    const validated = formValidationKeys[step][serviceType].map((key) => {
      switch (serviceType) {
        case CleaningServiceTypes.HOME:
          const homeCleaningValue =
            homeCleaningFormValues[
              key as keyof IContactInformation | keyof IMeetingInformation
            ];
          if (
            !homeCleaningValue ||
            !testValueAgainstRule(key, homeCleaningValue)
          ) {
            handleSetFieldError(key, true);
            return false;
          } else {
            handleSetFieldError(key, false);
            return true;
          }
        case CleaningServiceTypes.WINDOW:
          const windowCleaningValue =
            windowCleaningFormValues[
              key as keyof Omit<IContactInformation, 'sqmSize'>
            ];
          if (
            !windowCleaningValue ||
            !testValueAgainstRule(key, windowCleaningValue)
          ) {
            handleSetFieldError(key, true);
            return false;
          } else {
            handleSetFieldError(key, false);
            return true;
          }
        case DefaultCleaningServiceTypes.MAJOR:
        case DefaultCleaningServiceTypes.MOVING:
          const defaultCleaningValue =
            defaultCleaningFormValues[key as keyof IContactInformation];
          if (
            !defaultCleaningValue ||
            !testValueAgainstRule(key, defaultCleaningValue)
          ) {
            handleSetFieldError(key, true);
            return false;
          } else {
            handleSetFieldError(key, false);
            return true;
          }
        default:
          return true;
      }
    });
    return validated.every((bool) => bool);
  };

  const sendConfirmationEmails = () => {
    SendEmail({
      emailType: EmailType.CUSTOMER,
      firstName: homeCleaningFormValues.firstName,
      lastName: homeCleaningFormValues.lastName,
      startDate: homeCleaningFormValues.startDate,
      startTime: homeCleaningFormValues.startTime,
      meetingType: homeCleaningFormValues.meetingType,
      email: homeCleaningFormValues.email,
    });
    SendEmail({
      emailType: EmailType.INTERNAL,
      adress: homeCleaningFormValues.address,
      city: homeCleaningFormValues.city,
      email: homeCleaningFormValues.email,
      endTime: homeCleaningFormValues.endTime,
      firstName: homeCleaningFormValues.firstName,
      lastName: homeCleaningFormValues.lastName,
      meetingEmployeeId: homeCleaningFormValues.meetingEmployeeId,
      meetingType: homeCleaningFormValues.meetingType,
      phone: homeCleaningFormValues.phone,
      postalCode: homeCleaningFormValues.postalCode,
      sqmSize: homeCleaningFormValues.sqmSize,
      startDate: homeCleaningFormValues.startDate,
      startTime: homeCleaningFormValues.startTime,
    });
  };

  const submitForm = async () => {
    switch (serviceType) {
      case CleaningServiceTypes.HOME:
        createQuoteHomeCleaning({
          variables: {
            userInput: homeCleaningFormValues,
            responsibleEmployeeId:
              timewaveInformationState.employeeIdsConfig.responsibleEmployeeId,
            serviceIdsConfig: timewaveInformationState.serviceIdsConfig,
          },
        });
        break;
      case CleaningServiceTypes.WINDOW:
        createClientAndIssueWindowCleaning({
          variables: {
            userInput: windowCleaningFormValues,
            responsibleEmployeeId:
              timewaveInformationState.employeeIdsConfig.responsibleEmployeeId,
          },
        });
        break;
      case DefaultCleaningServiceTypes.MAJOR:
      case DefaultCleaningServiceTypes.MOVING:
        createClientAndIssueDefault({
          variables: {
            userInput: defaultCleaningFormValues,
            cleaningServiceType: serviceType,
            responsibleEmployeeId:
              timewaveInformationState.employeeIdsConfig.responsibleEmployeeId,
          },
        });
    }
  };

  const contextConfig = {
    formSubmissionState,
    submitForm,
    fieldErrors,
    validateFields,
    setFormValues,
    defaultCleaningFormValues,
    windowCleaningFormValues,
    homeCleaningFormValues,
    serviceType,
    setServiceType,
    timewaveInformationState,
    setTimewaveInformationStates,
    sendConfirmationEmails,
  };

  return (
    <FormContext.Provider value={contextConfig}>
      {children}
    </FormContext.Provider>
  );
};
