import React, { useState, useEffect } from 'react';
import { Box, Checkbox, Link, Typography } from '@mui/material';
import clsx from 'clsx';

import { IButtonGroup } from './types';
import { PrimaryButton, SecondaryButton, SubmitButton } from '../index';
import stringData from '../../assets/data/formStringsData.json';
import useStyles from './styles';
import { useFormContext } from '../../hooks';

export const ButtonGroup = ({
  handleBack,
  handleNext,
  submitForm,
  handleDisplayErrorMessage,
  activeStep,
  displayErrorMessage,
  stepsLength,
}: IButtonGroup) => {
  const classes = useStyles();
  const { validateFields } = useFormContext();
  const [submittedBeforeApproved, setSubmittedBeforeApproved] = useState(false);
  const [showErrormessage, setShowErrorMessage] = useState(false);

  const formStrings = stringData.form;

  useEffect(() => {
    setSubmittedBeforeApproved(false);
  }, []);
  const handleCheckbox = () => {
    if (submittedBeforeApproved) {
      setSubmittedBeforeApproved(false);
    } else {
      setSubmittedBeforeApproved(true);
      setShowErrorMessage(false);
    }
  };
  const handleSubmit = () => {
    if (validateFields(activeStep) && submittedBeforeApproved) {
      submitForm();
    } else if (!submittedBeforeApproved) {
      setShowErrorMessage(true);
    } else if (!validateFields(activeStep)) {
      handleDisplayErrorMessage(true);
    }
  };

  return (
    <Box className={classes.buttonGroup}>
      <Box className={classes.buttonBox}>
        {displayErrorMessage && (
          <Box
            textAlign={'center'}
            marginBottom={activeStep < stepsLength - 1 ? '15px' : '0px'}
          >
            <Typography variant="body2" color={'red'}>
              {formStrings.errorHelperText}
            </Typography>
          </Box>
        )}
        {activeStep < stepsLength - 1 ? (
          <PrimaryButton
            text={formStrings.buttons.nextStep}
            onClick={handleNext}
          ></PrimaryButton>
        ) : (
          <>
            {showErrormessage && (
              <Typography className={classes.error}>
                {formStrings.integrityPolicy.error}
              </Typography>
            )}
            <Box className={classes.checkboxContainer}>
              <Checkbox
                checked={submittedBeforeApproved}
                className={clsx(classes.checkbox, {
                  [classes.error]: showErrormessage,
                })}
                required={true}
                onChange={handleCheckbox}
              />
              <Typography>{formStrings.integrityPolicy.preLink}</Typography>
              <Link
                target="_blank"
                href={formStrings.integrityPolicy.linkUrl}
                className={classes.link}
              >
                {formStrings.integrityPolicy.linkText}
              </Link>
            </Box>
            <Box className={classes.submitButton}>
              <SubmitButton
                text={formStrings.buttons.submit}
                onClick={handleSubmit}
              ></SubmitButton>
            </Box>
          </>
        )}
      </Box>
      <Box className={classes.buttonBox}>
        {activeStep > 0 && (
          <SecondaryButton
            text={formStrings.buttons.previousStep}
            onClick={handleBack}
          ></SecondaryButton>
        )}
      </Box>
    </Box>
  );
};
