import {
  CleaningServiceTypes,
  DefaultCleaningServiceTypes,
  MeetingTypes,
  MullionAttachmentTypes,
  ResidenceAccessTypes,
  WindowPropertyDescriptions,
  WindowServiceTypes,
  WindowTypes,
} from '../contexts';

export const enumToString = (
  enumValue:
    | DefaultCleaningServiceTypes
    | CleaningServiceTypes
    | MeetingTypes
    | WindowServiceTypes
    | WindowTypes
    | WindowPropertyDescriptions
    | ResidenceAccessTypes
    | MullionAttachmentTypes
): string => {
  switch (enumValue) {
    case DefaultCleaningServiceTypes.MAJOR:
      return 'Storstädning';
    case DefaultCleaningServiceTypes.MOVING:
      return 'Flyttstädning';
    case CleaningServiceTypes.HOME:
      return 'Hemstädning';
    case CleaningServiceTypes.WINDOW:
      return 'Fönsterputsning';
    case MeetingTypes.PHYSICAL:
      return 'Fysiskt möte (30 minuter)';
    case MeetingTypes.VIDEO:
      return 'Videomöte (20 minuter)';
    case WindowServiceTypes.WINDOWS:
      return 'Fönster';
    case WindowServiceTypes.GLASS_BALCONY:
      return 'Inglasad balkong';
    case WindowServiceTypes.WINDOWS_AND_GLASS_BALCONY:
      return 'Fönster och inglasad balkong';
    case WindowTypes.OVERHUNG:
      return 'Överhängt';
    case WindowTypes.WITH_MULLION:
      return 'Med spröjs';
    case WindowTypes.WITHOUT_MULLION:
      return 'Utan spröjs';
    case WindowPropertyDescriptions.DOUBLE:
      return 'Dubbelsidiga fönster (fyra sidor att putsa)';
    case WindowPropertyDescriptions.SINGLE:
      return 'Enkelsidiga fönster (två sidor att putsa, in & utsida)';
    case WindowPropertyDescriptions.OUTSIDE:
      return 'Endast utsida';
    case WindowPropertyDescriptions.INSIDE:
      return 'Endast insida';
    case ResidenceAccessTypes.KEYS:
      return 'Jag lämnar nycklarna till Alberts kontor senast klockan 12 dagen före utförandet';
    case ResidenceAccessTypes.IN_PERSON:
      return 'Jag öppnar åt personalen';
    case MullionAttachmentTypes.REMOVABLE:
      return 'Med löstagbar spröjs';
    case MullionAttachmentTypes.FIXED:
      return 'Med fast spröjs';
  }
};
