import { createContext, Dispatch, SetStateAction } from 'react';

import { IFormState, initialFormState } from './state';
import {
  CleaningServiceTypes,
  DefaultCleaningServiceTypes,
  IDefaultCleaningForm,
  IHomeCleaningForm,
  IWindowCleaningForm,
  ValueOf,
} from './types';

export interface IFormContext extends IFormState {
  validateFields: (step: number) => boolean;
  setServiceType: Dispatch<
    SetStateAction<DefaultCleaningServiceTypes | CleaningServiceTypes>
  >;
  setFormValues: (
    key:
      | Partial<keyof IWindowCleaningForm>
      | Partial<keyof IHomeCleaningForm>
      | Partial<keyof IDefaultCleaningForm>,
    value:
      | Partial<ValueOf<IWindowCleaningForm>>
      | Partial<ValueOf<IHomeCleaningForm>>
      | Partial<ValueOf<IDefaultCleaningForm>>
  ) => void;
  submitForm: () => void;
  sendConfirmationEmails: () => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialFormContext: IFormContext = {
  ...initialFormState,
  submitForm: stub,
  setFormValues: stub,
  setServiceType: stub,
  validateFields: stub,
  sendConfirmationEmails: stub,
};

export const FormContext = createContext(initialFormContext);
