export interface IHomeCleaningForm
  extends IContactInformation,
    IMeetingInformation {
  meetingType: MeetingTypes;
}

export interface IDefaultCleaningForm extends IContactInformation {
  cleaningServiceType?: DefaultCleaningServiceTypes;
}

export interface IWindowCleaningForm extends IWindowContactInformation {
  serviceType: WindowServiceTypes;
  nrOfNonMullionWindows: number;
  nrOfMullionWindows: number;
  nrOfOverhungWindows: number;
  mullionAttachmentType?: MullionAttachmentTypes;
  windowPropertyDescription: WindowPropertyDescriptions;
  isLadderNeeded: boolean;
  isFrameCleaningNeeded: boolean;
}

export type ValueOf<T> = T[keyof T];

export interface IFormSubmissionState {
  data: boolean;
  loading: boolean;
  error: boolean;
}

export interface IWindowContactInformation
  extends Omit<IContactInformation, 'sqmSize'> {
  portCode: string;
  hasPortCode: boolean;
  residenceAccessType: ResidenceAccessTypes;
}

export interface IContactInformation {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  sqmSize: string;
}

export interface IMeetingInformation {
  meetingEmployeeId: string;
  startDate: string;
  startTime: string;
  endTime: string;
}
export interface ITimewaveInformationState {
  employeeIdsConfig: EmployeeIdsConfig;
  serviceIdsConfig: ServiceIdsConfig;
}

type EmployeeIdsConfig = {
  responsibleEmployeeId: string;
  physicalMeetingEmployeeIds: Cities;
  digitalMeetingEmployeeIds: Cities;
};
type Cities = {
  gothenburg: string;
  stockholm: string;
  uppsala?: string;
};
type ServiceIdsConfig = {
  physicalMeetingServiceIds: Cities;
  digitalMeetingServiceIds: Cities;
};

export enum MeetingTypes {
  VIDEO = 'VIDEO',
  PHYSICAL = 'PHYSICAL',
}

export enum CleaningServiceTypes {
  HOME = 'HOME',
  WINDOW = 'WINDOW',
}

export enum DefaultCleaningServiceTypes {
  MOVING = 'MOVING',
  MAJOR = 'MAJOR',
}

export enum WindowServiceTypes {
  WINDOWS = 'WINDOWS',
  GLASS_BALCONY = 'GLASS_BALCONY',
  WINDOWS_AND_GLASS_BALCONY = 'WINDOWS_AND_GLASS_BALCONY',
}

export enum WindowTypes {
  WITHOUT_MULLION = 'WITHOUT_MULLION',
  WITH_MULLION = 'WITH_MULLION',
  OVERHUNG = 'OVERHUNG',
}

export enum WindowPropertyDescriptions {
  DOUBLE = 'DOUBLE',
  SINGLE = 'SINGLE',
  OUTSIDE = 'OUTSIDE',
  INSIDE = 'INSIDE',
}

export enum ResidenceAccessTypes {
  KEYS = 'KEYS',
  IN_PERSON = 'IN_PERSON',
}

export enum MullionAttachmentTypes {
  REMOVABLE = 'REMOVABLE',
  FIXED = 'FIXED',
}
