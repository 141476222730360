import {
  CleaningServiceTypes,
  DefaultCleaningServiceTypes,
  MeetingTypes,
  MullionAttachmentTypes,
  WindowPropertyDescriptions,
  WindowServiceTypes,
  WindowTypes,
} from '../../contexts';

export const services = [
  CleaningServiceTypes.HOME,
  CleaningServiceTypes.WINDOW,
  DefaultCleaningServiceTypes.MAJOR,
  DefaultCleaningServiceTypes.MOVING,
];

export const meetingTypes = [
  {
    type: MeetingTypes.VIDEO,
    gapLengthHours: 0.5,
    defaultEmployeeIds: '858', // replace with employeeIds from albertsstäd
  },
  {
    type: MeetingTypes.PHYSICAL,
    gapLengthHours: 1,
    defaultEmployeeIds: '858', // replace with employeeIds from albertsstäd
  },
];

export const windowTypes = [
  WindowTypes.WITHOUT_MULLION,
  WindowTypes.OVERHUNG,
  WindowTypes.WITH_MULLION,
];

export const windowServiceTypes = [
  WindowServiceTypes.WINDOWS,
  WindowServiceTypes.GLASS_BALCONY,
  WindowServiceTypes.WINDOWS_AND_GLASS_BALCONY,
];

export const mullionAttachmentTypes = [
  MullionAttachmentTypes.REMOVABLE,
  MullionAttachmentTypes.FIXED,
];

export const windowPropertyDescriptions = [
  WindowPropertyDescriptions.DOUBLE,
  WindowPropertyDescriptions.SINGLE,
  WindowPropertyDescriptions.OUTSIDE,
  WindowPropertyDescriptions.INSIDE,
];
