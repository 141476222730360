import React from 'react';

export interface IUsageContext {
  userStartedForm: (category: string) => void;
  userVisitedPage: (category: string, action: string) => void;
  userSubmittedForm: (category: string, service: string) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialUsageContext: IUsageContext = {
  userStartedForm: stub,
  userVisitedPage: stub,
  userSubmittedForm: stub,
};

export const UsageContext = React.createContext(initialUsageContext);
