import React, { useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';

import { CustomStepper } from '../CustomStepper';
import { ButtonGroup } from '../ButtonGroup';
import stringData from '../../assets/data/formStringsData.json';
import {
  ChooseService,
  ContactInformation,
  ResidenceInformation,
} from '../steps';
import { useFormContext, useUsageContext } from '../../hooks';
import {
  CleaningServiceTypes,
  DefaultCleaningServiceTypes,
} from '../../contexts';
import useStyles from './styles';
import { GAAction, GACategory } from '../../contexts/usage/helpers';

declare var fbq: Function;

export const Form = () => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const {
    validateFields,
    setFormValues,
    serviceType,
    submitForm,
    formSubmissionState,
    sendConfirmationEmails,
  } = useFormContext();
  const formString = stringData.form;
  const { height, ref } = useResizeDetector();
  const { userStartedForm, userVisitedPage, userSubmittedForm } =
    useUsageContext();
  const [activeStepId, setActiveStepId] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  useEffect(() => {
    userStartedForm(GACategory.STANDARD_FORM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (activeStepId) {
      case 0:
        userVisitedPage(GACategory.STANDARD_FORM, GAAction.FORM_PAGE_1);
        break;
      case 1:
        userVisitedPage(GACategory.STANDARD_FORM, GAAction.FORM_PAGE_2);
        break;
      case 2:
        userVisitedPage(GACategory.STANDARD_FORM, GAAction.FORM_PAGE_3);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepId]);

  const scrollToTop = () => {
    if (window.parent) {
      window.parent.postMessage(
        { task: 'scroll_top' },
        formString.parentUrl // switch to local development url in development
        // This is the url for the test site 'https://www.bettysstad.se/test/'
      );
    }
  };

  const handleNext = () => {
    if (validateFields(activeStepId)) {
      setActiveStepId((prevActiveStepId) => prevActiveStepId + 1);
      setDisplayErrorMessage(false);
      scrollToTop();
    } else {
      setDisplayErrorMessage(true);
    }
  };

  const resetMeetingFormValues = () => {
    setFormValues('meetingEmployeeId', '');
    setFormValues('startDate', '');
    setFormValues('startTime', '');
    setFormValues('endTime', '');
  };

  const handleBack = () => {
    // reset selected meeting when going back to ChooseService step
    if (activeStepId === 1 && serviceType === CleaningServiceTypes.HOME) {
      resetMeetingFormValues();
    }
    setActiveStepId((prevActiveStepId) => prevActiveStepId - 1);
    scrollToTop();
    setDisplayErrorMessage(false);
  };

  const handleScrollToButton = () => {
    if (window.parent) {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        window.parent.postMessage(
          {
            task: 'scroll_to_button',
            offset: rect.top,
          },
          formString.parentUrl
        );
      }
    }
  };

  useEffect(() => {
    if (formSubmissionState.data && window.top) {
      switch (serviceType) {
        case CleaningServiceTypes.HOME:
          userSubmittedForm(
            GACategory.STANDARD_FORM,
            CleaningServiceTypes.HOME
          );
          setIsSubmitted(true);
          sendConfirmationEmails();
          break;
        case CleaningServiceTypes.WINDOW:
          userSubmittedForm(
            GACategory.STANDARD_FORM,
            CleaningServiceTypes.WINDOW
          );
          setIsSubmitted(true);
          break;
        case DefaultCleaningServiceTypes.MAJOR:
          userSubmittedForm(
            GACategory.STANDARD_FORM,
            DefaultCleaningServiceTypes.MAJOR
          );
          setIsSubmitted(true);
          break;
        case DefaultCleaningServiceTypes.MOVING:
          userSubmittedForm(
            GACategory.STANDARD_FORM,
            DefaultCleaningServiceTypes.MOVING
          );
          setIsSubmitted(true);
          break;
      }
      setDisplayErrorMessage(false);
      fbq('track', 'CompleteRegistration');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmissionState.data]);

  const steps = [
    {
      label: 'Välj tjänst',
      component: <ChooseService handleScrollToButton={handleScrollToButton} />,
    },
    {
      label: 'Om din bostad',
      component: <ResidenceInformation />,
    },
    {
      label: 'Kontaktuppgifter',
      component: <ContactInformation />,
    },
  ];
  const setIframeHeight = () => {
    // sends height to parent iframe
    if (window.parent) {
      window.parent.postMessage(
        { task: 'set_iframe_height', height: height },
        formString.parentUrl // switch to local development url in development
      );
    }
  };
  useEffect(() => {
    setIframeHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  if (formSubmissionState.loading) {
    return (
      <Box className={classes.root}>
        <Box ref={ref}>
          <Box textAlign={'center'} marginBottom={'55px'}>
            <Typography variant="h1">{formString.heading}</Typography>
          </Box>
          <CustomStepper activeStep={activeStepId} steps={steps} />
          <Box className={classes.loadingContainer} sx={{ height: 100 }}>
            <CircularProgress size={65} />
          </Box>
        </Box>
      </Box>
    );
  }

  const handleDisplayErrorMessage = (boolean: boolean) => {
    setDisplayErrorMessage(boolean);
  };

  return (
    <Box className={classes.root}>
      <Box ref={ref}>
        {isSubmitted ? (
          <Box textAlign={'center'} padding={'15% 0px'}>
            <Typography variant="h1" marginBottom={'15px'}>
              {stringData.thankYou.heading}
            </Typography>
            <Typography variant="body1">{stringData.thankYou.text}</Typography>
            <Typography variant="body1">
              {stringData.thankYou.subHeading}
            </Typography>
          </Box>
        ) : (
          <>
            <Box textAlign={'center'} marginBottom={'55px'}>
              <Typography variant="h1">{formString.heading}</Typography>
            </Box>
            <CustomStepper activeStep={activeStepId} steps={steps} />
            {steps.map(
              (step, index) =>
                index === activeStepId && (
                  <Box key={index}>{step.component}</Box>
                )
            )}
            {formSubmissionState.error && (
              <>
                <Typography className={classes.errorText}>
                  {formString.formSubmissionErrorText}
                </Typography>
                <Box className={classes.linkContainer}>
                  <Typography className={classes.link}>
                    {formString.bookThroughOldForm}
                  </Typography>
                  <Link
                    target="_blank"
                    href={formString.oldFormUrl}
                    className={classes.link}
                  >
                    {formString.here}
                  </Link>
                </Box>
              </>
            )}
            <Box ref={buttonRef}></Box>
            <ButtonGroup
              activeStep={activeStepId}
              stepsLength={steps.length}
              displayErrorMessage={displayErrorMessage}
              handleDisplayErrorMessage={(boolean) =>
                handleDisplayErrorMessage(boolean)
              }
              submitForm={submitForm}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
