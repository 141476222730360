import React from 'react';
import { Checkbox as MuiCheckbox, Grid, Typography } from '@mui/material';

import {
  CleaningServiceTypes,
  DefaultCleaningServiceTypes,
  IWindowCleaningForm,
  ResidenceAccessTypes,
} from '../../../contexts';
import { Checkbox } from '../../Checkbox';
import { FormSection } from '../../FormSection';
import { Input } from '../../Input';
import { useFormContext } from '../../../hooks';
import { enumToString } from '../../../helpers';
import stringData from '../../../assets/data/formStringsData.json';
import useStyles from './styles';

export const ContactInformation = () => {
  const classes = useStyles();
  const contactInformationStrings = stringData.steps.contactInformation;

  const {
    windowCleaningFormValues,
    setFormValues,
    serviceType,
    fieldErrors,
    homeCleaningFormValues,
    defaultCleaningFormValues,
  } = useFormContext();

  const getCurrentFormValues = () => {
    switch (serviceType) {
      case CleaningServiceTypes.HOME:
        return homeCleaningFormValues;
      case CleaningServiceTypes.WINDOW:
        return windowCleaningFormValues;
      case DefaultCleaningServiceTypes.MAJOR:
      case DefaultCleaningServiceTypes.MOVING:
        return defaultCleaningFormValues;
    }
  };

  return (
    <>
      <FormSection
        title={contactInformationStrings.default.contactInformation.title}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <Input
              placeholder={
                contactInformationStrings.default.contactInformation.fields
                  .firstName.placeholder
              }
              value={getCurrentFormValues()['firstName']}
              errorHelperText={
                contactInformationStrings.default.contactInformation.fields
                  .firstName.errorHelperText
              }
              error={fieldErrors.firstName}
              onChange={(value) => setFormValues('firstName', value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Input
              placeholder={
                contactInformationStrings.default.contactInformation.fields
                  .lastName.placeholder
              }
              errorHelperText={
                contactInformationStrings.default.contactInformation.fields
                  .lastName.errorHelperText
              }
              value={getCurrentFormValues()['lastName']}
              error={fieldErrors.lastName}
              onChange={(value) => setFormValues('lastName', value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Input
              placeholder={
                contactInformationStrings.default.contactInformation.fields
                  .phone.placeholder
              }
              errorHelperText={
                contactInformationStrings.default.contactInformation.fields
                  .phone.errorHelperText
              }
              type="number"
              value={getCurrentFormValues()['phone']}
              error={fieldErrors.phone}
              onChange={(value) => setFormValues('phone', value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Input
              placeholder={
                contactInformationStrings.default.contactInformation.fields
                  .email.placeholder
              }
              errorHelperText={
                contactInformationStrings.default.contactInformation.fields
                  .email.errorHelperText
              }
              error={fieldErrors.email}
              value={getCurrentFormValues()['email']}
              onChange={(value) => setFormValues('email', value)}
            />
          </Grid>
        </Grid>
      </FormSection>
      {serviceType === CleaningServiceTypes.WINDOW && (
        <>
          <FormSection title={contactInformationStrings.window.address.title}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  placeholder={
                    contactInformationStrings.window.address.fields.address
                      .placeholder
                  }
                  errorHelperText={
                    contactInformationStrings.window.address.fields.address
                      .errorHelperText
                  }
                  error={fieldErrors.address}
                  value={getCurrentFormValues()['address']}
                  onChange={(value) => setFormValues('address', value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  placeholder={
                    contactInformationStrings.window.address.fields.postalCode
                      .placeholder
                  }
                  errorHelperText={
                    contactInformationStrings.window.address.fields.postalCode
                      .errorHelperText
                  }
                  error={fieldErrors.postalCode}
                  value={getCurrentFormValues()['postalCode']}
                  type="number"
                  onChange={(value) => setFormValues('postalCode', value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  placeholder={
                    contactInformationStrings.window.address.fields.city
                      .placeholder
                  }
                  errorHelperText={
                    contactInformationStrings.window.address.fields.city
                      .errorHelperText
                  }
                  error={fieldErrors.city}
                  value={getCurrentFormValues()['city']}
                  onChange={(value) => setFormValues('city', value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  placeholder={
                    contactInformationStrings.window.address.fields.portCode
                      .placeholder
                  }
                  errorHelperText={
                    contactInformationStrings.window.address.fields.portCode
                      .errorHelperText
                  }
                  error={false}
                  value={
                    (getCurrentFormValues() as IWindowCleaningForm)['portCode']
                  }
                  onChange={(value) => setFormValues('portCode', value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <p> </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.checkboxContainer}
              >
                <MuiCheckbox
                  checked={!windowCleaningFormValues.hasPortCode}
                  className={classes.checkbox}
                  onClick={() =>
                    setFormValues(
                      'hasPortCode',
                      !windowCleaningFormValues.hasPortCode
                    )
                  }
                />

                <Typography>
                  {contactInformationStrings.window.address.checkbox}
                </Typography>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection
            title={contactInformationStrings.window.residenceAccessType.title}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <Checkbox
                  text={enumToString(ResidenceAccessTypes.IN_PERSON)}
                  isChecked={
                    windowCleaningFormValues.residenceAccessType ===
                    ResidenceAccessTypes.IN_PERSON
                  }
                  onClick={() =>
                    setFormValues(
                      'residenceAccessType',
                      ResidenceAccessTypes.IN_PERSON
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Checkbox
                  text={enumToString(ResidenceAccessTypes.KEYS)}
                  isChecked={
                    windowCleaningFormValues.residenceAccessType ===
                    ResidenceAccessTypes.KEYS
                  }
                  onClick={() =>
                    setFormValues(
                      'residenceAccessType',
                      ResidenceAccessTypes.KEYS
                    )
                  }
                />
              </Grid>
            </Grid>
          </FormSection>
        </>
      )}
    </>
  );
};
