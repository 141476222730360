import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import {
  meetingTypes,
  mullionAttachmentTypes,
  services,
  windowPropertyDescriptions,
  windowServiceTypes,
  windowTypes,
} from '../../../assets';
import stringData from '../../../assets/data/formStringsData.json';
import { CleaningServiceTypes, WindowTypes } from '../../../contexts';
import { FormSection } from '../../FormSection';
import { Calendar } from '../../Calendar';
import { WindowInput } from '../../WindowInput';
import { useFormContext } from '../../../hooks';
import { enumToString, validatePostalCodeRange } from '../../../helpers';
import { MultipleChoiceCheckbox, Checkbox } from '../../Checkbox';
import { Input } from '../../Input';
import useStyles from './styles';

interface IChooseService {
  handleScrollToButton: () => void;
}
export const ChooseService = ({ handleScrollToButton }: IChooseService) => {
  const {
    homeCleaningFormValues,
    windowCleaningFormValues,
    serviceType,
    fieldErrors,
    setServiceType,
    setFormValues,
  } = useFormContext();
  const classes = useStyles();

  const chooseServiceString = stringData.steps.chooseService;
  const [noMeetingFetchedOrSelectedError, setNoMeetingFetchedOrSelectedError] =
    useState(false);

  const [postalCodeRangeError, setPostalCodeRangeError] = useState<boolean>();

  const handleValidatePostalCode = () => {
    if (!homeCleaningFormValues.postalCode) return;
    if (validatePostalCodeRange(homeCleaningFormValues.postalCode)) {
      setPostalCodeRangeError(false);
    } else {
      setPostalCodeRangeError(true);
    }
  };

  const handleValidateInput = () => {
    handleValidatePostalCode();
    setNoMeetingFetchedOrSelectedError(false);
  };

  useEffect(() => {
    if (
      fieldErrors.startDate ||
      fieldErrors.startTime ||
      fieldErrors.endTime ||
      fieldErrors.meetingEmployeeId
    ) {
      setNoMeetingFetchedOrSelectedError(true);
    } else {
      setNoMeetingFetchedOrSelectedError(false);
    }
  }, [fieldErrors]);

  const handleSetWindowTypeQuantity = (
    windowType: WindowTypes,
    quantity: number
  ) => {
    switch (windowType) {
      case WindowTypes.WITHOUT_MULLION: {
        setFormValues('nrOfNonMullionWindows', quantity);
        break;
      }
      case WindowTypes.WITH_MULLION: {
        setFormValues('nrOfMullionWindows', quantity);
        break;
      }
      case WindowTypes.OVERHUNG: {
        setFormValues('nrOfOverhungWindows', quantity);
        break;
      }
    }
  };

  const handleDisplayWindowTypeQuantity = (windowType: WindowTypes) => {
    switch (windowType) {
      case WindowTypes.WITHOUT_MULLION: {
        if (windowCleaningFormValues.nrOfMullionWindows > 0) {
          return windowCleaningFormValues.nrOfNonMullionWindows;
        }
        return undefined;
      }
      case WindowTypes.WITH_MULLION: {
        if (windowCleaningFormValues.nrOfMullionWindows > 0) {
          return windowCleaningFormValues.nrOfMullionWindows;
        }
        return undefined;
      }
      case WindowTypes.OVERHUNG: {
        if (windowCleaningFormValues.nrOfOverhungWindows > 0) {
          return windowCleaningFormValues.nrOfOverhungWindows;
        }
        return undefined;
      }
    }
  };

  return (
    <Box>
      <FormSection title={chooseServiceString.default.chooseService.title}>
        <Grid container spacing={1}>
          {services.map((service, index) => (
            <Grid item key={index} xs={12} sm={12} md={6}>
              <Checkbox
                text={enumToString(service)}
                isChecked={serviceType === service}
                onClick={() => setServiceType(service)}
              />
            </Grid>
          ))}
        </Grid>
      </FormSection>

      {serviceType === CleaningServiceTypes.HOME && (
        <>
          <FormSection title={chooseServiceString.home.meetingType.title}>
            <Grid container spacing={1}>
              {meetingTypes.map((meetingType, index) => (
                <Grid item key={index} xs={12} sm={12} md={6}>
                  <Checkbox
                    isChecked={
                      meetingType.type === homeCleaningFormValues.meetingType
                    }
                    onClick={() => {
                      setFormValues('meetingType', meetingType.type);
                      setNoMeetingFetchedOrSelectedError(false);
                    }}
                    text={enumToString(meetingType.type)}
                  />
                </Grid>
              ))}
            </Grid>
          </FormSection>

          <FormSection title={chooseServiceString.home.meetingDateTime.title}>
            {postalCodeRangeError === false ? (
              meetingTypes.map(
                (meetingType, index) =>
                  meetingType.type === homeCleaningFormValues.meetingType && (
                    <Calendar
                      handleScrollToButton={handleScrollToButton}
                      key={index}
                      gapLengthHours={meetingType.gapLengthHours}
                      meetingType={meetingType.type}
                      noMeetingSelectedError={noMeetingFetchedOrSelectedError}
                    />
                  )
              )
            ) : (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="body1">
                    {
                      chooseServiceString.home.meetingDateTime.fields.postalCode
                        .label
                    }
                  </Typography>
                </Grid>
                <Grid container item>
                  <Grid item xs className={classes.postalCodeInputContainer}>
                    <Input
                      placeholder={
                        chooseServiceString.home.meetingDateTime.fields
                          .postalCode.placeholder
                      }
                      value={homeCleaningFormValues.postalCode}
                      errorHelperText={
                        chooseServiceString.home.meetingDateTime.fields
                          .postalCode.errorHelperText
                      }
                      error={fieldErrors.postalCode}
                      onChange={(value) => setFormValues('postalCode', value)}
                    />
                  </Grid>
                  <Grid item>
                    <button
                      className={classes.primaryButton}
                      onClick={handleValidateInput}
                    ></button>
                  </Grid>
                </Grid>
                <Grid item>
                  {postalCodeRangeError === true &&
                    !noMeetingFetchedOrSelectedError && (
                      <Typography className={classes.errorText}>
                        {
                          chooseServiceString.home.meetingDateTime.fields
                            .postalCode.rangeErrorText
                        }
                      </Typography>
                    )}
                  {noMeetingFetchedOrSelectedError && (
                    <Typography className={classes.errorText}>
                      {
                        chooseServiceString.home.meetingDateTime
                          .noFetchedOrSelectedMeeting
                      }
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
          </FormSection>
        </>
      )}

      {serviceType === CleaningServiceTypes.WINDOW && (
        <Box>
          <FormSection
            title={chooseServiceString.window.windowServiceType.title}
          >
            <Grid container spacing={1}>
              {windowServiceTypes.map((windowServiceType, index) => (
                <Grid item key={index} xs={12} sm={12} md={6}>
                  <Checkbox
                    text={enumToString(windowServiceType)}
                    isChecked={
                      windowServiceType === windowCleaningFormValues.serviceType
                    }
                    onClick={() =>
                      setFormValues('serviceType', windowServiceType)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </FormSection>

          <FormSection title={chooseServiceString.window.windowType.title}>
            <Grid container spacing={1}>
              {windowTypes.map((windowType, index) => (
                <Grid item key={index} xs={12} sm={12} md={6}>
                  <WindowInput
                    windowType={windowType}
                    onChange={handleSetWindowTypeQuantity}
                    value={handleDisplayWindowTypeQuantity(windowType)}
                  />
                </Grid>
              ))}

              {windowCleaningFormValues.nrOfMullionWindows > 0 && (
                <Grid item xs={12} sm={12} md={6}>
                  <MultipleChoiceCheckbox
                    choices={mullionAttachmentTypes.map((type) =>
                      enumToString(type)
                    )}
                    checkedIndex={mullionAttachmentTypes.findIndex(
                      (type) =>
                        type === windowCleaningFormValues.mullionAttachmentType
                    )}
                    onCheck={(index) =>
                      setFormValues(
                        'mullionAttachmentType',
                        typeof index === 'number'
                          ? mullionAttachmentTypes[index]
                          : undefined
                      )
                    }
                  />
                </Grid>
              )}
            </Grid>
          </FormSection>

          <FormSection
            title={chooseServiceString.window.windowPropertyDescription.title}
          >
            <Grid container spacing={1}>
              {windowPropertyDescriptions.map(
                (windowPropertyDescription, index) => (
                  <Grid item key={index} xs={12} sm={12} md={6}>
                    <Checkbox
                      text={enumToString(windowPropertyDescription)}
                      isChecked={
                        windowPropertyDescription ===
                        windowCleaningFormValues.windowPropertyDescription
                      }
                      onClick={() =>
                        setFormValues(
                          'windowPropertyDescription',
                          windowPropertyDescription
                        )
                      }
                    />
                  </Grid>
                )
              )}
            </Grid>
          </FormSection>
        </Box>
      )}
    </Box>
  );
};
