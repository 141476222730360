import { gql } from '@apollo/client';

export const CREATE_CLIENT_AND_ISSUE_DEFAULT = gql`
  mutation createClientAndIssueDefault(
    $cleaningServiceType: DefaultCleaningServiceType!
    $userInput: UserInputDefault!
    $responsibleEmployeeId: String!
  ) {
    createClientAndIssueDefault(
      cleaningServiceType: $cleaningServiceType
      userInput: $userInput
      responsibleEmployeeId: $responsibleEmployeeId
    )
  }
`;
