import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    primaryButton: {
      backgroundColor: theme.palette.primary.main,
      border: `2px ${theme.palette.primary.main} solid`,
      padding: '15px 25px',
      borderRadius: '0 40px 40px 0',
      height: '100%',
      transition: 'border .4s ease-out, background .4s ease-out',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        '&::after': {
          color: theme.palette.primary.main,
        },
      },
      '&::after': {
        content: '"c"',
        fontSize: 19,
        fontFamily: 'alberts',
        color: theme.palette.common.white,
        transition: 'color .4s ease-out',
      },
    },
    postalCodeInputContainer: {
      '& *': {
        borderRadius: `${theme.shape.borderRadius}px 0px 0px ${theme.shape.borderRadius}px !important`,
      },
    },
    errorText: {
      color: theme.palette.error.main,
    },
  })
);
